import * as React from "react";
 
function PrivacyPolicy() {
    return (
    <div className="PrivacyPolicy">
        <h1>Privacy Policy</h1>
        <p>Last updated: November 2, 2024</p>

        <h2>Introduction</h2>
        <p>
            Welcome to Daedalus Solutions ("us", "we", or "our"). This Privacy Policy explains how we collect, use, and protect your information when you use our web application.
        </p>

        <h2>Information We Collect</h2>
        <p>
            We collect the following types of information:
        </p>
        <ul>
            <li><strong>Account Information:</strong> We collect your name and email address when you create an account.</li>
            <li><strong>Error Logs:</strong> We may collect information related to errors and bugs in the application to improve our services. This information is collected anonymously and does not include personal identifiers.</li>
        </ul>

        <h2>Cookies</h2>
        <p>
            We use a validation cookie to verify user accounts. This cookie is essential for the functionality of our web application and does not track your activity outside of our services.
        </p>

        <h2>Payment Processing</h2>
        <p>
            We accept payments through Stripe and PayPal. We do not store your credit card information. All payment transactions are processed securely by our payment processors, and we encourage you to review their privacy policies for more information.
        </p>

        <h2>How We Use Your Information</h2>
        <p>
            We use the information we collect for the following purposes:
        </p>
        <ul>
            <li>To create and manage your account.</li>
            <li>To communicate with you regarding your account and our services.</li>
            <li>To improve our web application and fix bugs based on error logs.</li>
        </ul>

        <h2>Data Security</h2>
        <p>
            We take the security of your personal information seriously. We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure.
        </p>

        <h2>Your Rights</h2>
        <p>
            You have the right to access, correct, or delete your personal information. If you wish to exercise any of these rights, please contact us at [Your Contact Email].
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>

        <h2>Contact Us</h2>
        <p>
            If you have any questions about this Privacy Policy, please contact us:
        </p>
        <ul>
            <li>Email: dylan@daedalussolutions.online</li>
        </ul>
    </div>
    );
}

export default PrivacyPolicy;