import * as React from "react";
 
function AddingDNSRecords() {
    return (
        <div className="dns-instructions">
             <h2>How to Add DNS Records</h2>
      <p>
        Adding DNS records is an essential part of managing your domain. DNS records help direct traffic to your website and configure various services associated with your domain. In this tutorial, we will guide you through the steps to add DNS records.
      </p>

      <h2>Step 1: Access Your Domain Registrar</h2>
      <p>
        First, log in to your domain registrar's website (e.g., GoDaddy, Namecheap, etc.). Once logged in, navigate to the domain management section.
      </p>

      <h2>Step 2: Locate DNS Management</h2>
      <p>
        Find the DNS management or DNS settings option for the domain you want to modify. This section allows you to view and edit your DNS records.
      </p>

      <h2>Step 3: Add a New DNS Record</h2>
      <p>
        Look for an option to add a new DNS record. You will typically see a button labeled "Add Record" or "Add DNS Record." Click on it to proceed.
      </p>

      <h3>Choose the Record Type</h3>
      <p>
        You will need to select the type of DNS record you want to add. Common types include:
      </p>
      <ul>
        <li><strong>A Record:</strong> Points your domain to an IP address.</li>
        <li><strong>AAAA Record:</strong> Points your domain to an IPv6 address. This allows HTTPS connections.</li>
        <li><strong>CNAME Record:</strong> Points your domain to another domain name.</li>
        <li><strong>TXT Record:</strong> Allows you to add text information to your domain.</li>
      </ul>

      <h3>Fill in the Record Details</h3>
      <p>
        After selecting the record type, fill in the required details:
      </p>
      <ul>
        <li><strong>Name:</strong> The subdomain or domain name (e.g., "www" or "example.com").</li>
        <li><strong>Value:</strong> The IP address or target domain (e.g., "192.0.2.1" for an A record).</li>
        <li><strong>TTL:</strong> Time to Live, which determines how long the record is cached (default is usually fine).</li>
      </ul>

      <p>
        If you are configuring your DNS records for hosting provided by Daedalus Solutions, refer to the provided records on the <a href="/add-website">'Add Website'</a> form.
      </p>

      <h2>Step 4: Save Your Changes</h2>
      <p>
        Once you have filled in the details, click the "Save" or "Add Record" button to save your changes. It may take some time for the changes to propagate across the internet.
      </p>

      <h2>Step 5: Verify Your DNS Records</h2>
      <p>
        After a few minutes, you can verify that your DNS records have been added correctly. You can use online tools like <a href="https://www.whatsmydns.net/" target="_blank" rel="noopener noreferrer">What's My DNS</a> to check the status of your DNS records.
      </p>
      <p>
        If you have configured your DNS records for Daedalus Solutions hosting, your DNS records will be verified automatically and your Wordpress site will be live after a few minutes.
      </p>
        </div>
    );
}

export default AddingDNSRecords;