import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import loadingIcon from '../assets/images/transistor_loading_icon.gif'


function UpdatePassword() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [password, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    const response = await axios.get('https://daedalussolutions.online/validate-token', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setIsValid(true);
                    }
                } catch (error) {
                    setIsValid(false);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        validateToken();
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            const response = await axios.post('https://daedalussolutions.online/update-password', {
                password,
                newPassword
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setSuccess('Password updated successfully!');
                setCurrentPassword('');
                setNewPassword('');
            }
        } catch (error) {
            setError('Failed to update password. Please check your current password and try again.');
        }
    };

    if (loading) {
        return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
    }

    if (!isValid) {
        localStorage.removeItem('token');
        return <Login setToken={setToken} />;
    }

    return (
        <div className="UpdatePassword">
            <h2>Update Password</h2>
            <div className='login-form'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Current Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>New Password:</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Update Password</button>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </form>
            </div>
        </div>
    );
}

export default UpdatePassword;
