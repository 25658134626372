import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import Settings from './Settings';
import moment from 'moment';
import loadingIcon from '../assets/images/transistor_loading_icon.gif'

function Dashboard() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUserData] = useState(null);
    const [plan, setPlan] = useState(null);
    const [userHostingPlan, setUserHostingPlanData] = useState(null);
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    const response = await axios.get('https://daedalussolutions.online/validate-token', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setIsValid(true);
                        const userResponse = await axios.get('https://daedalussolutions.online/user-data', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserData(userResponse.data);
    
                        const userPlanResponse = await axios.get('https://daedalussolutions.online/user-plan-data', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserHostingPlanData(userPlanResponse.data);
                        
                        if (userPlanResponse.data && userPlanResponse.data.length > 0) {
                            const planResponse = await axios.get(`https://daedalussolutions.online/api/hosting_plans/${userPlanResponse.data[0].hosting_plan_id}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            setPlan(planResponse.data);

                            const domainsResponse = await axios.get(`https://daedalussolutions.online/get-user-domains/${userResponse.data.id}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            if (domainsResponse.status === 200) {
                                if (Array.isArray(domainsResponse.data)) {
                                    setDomains(domainsResponse.data);
                                } else {
                                    setDomains([]); 
                                }
                            } else {
                                setDomains([]);
                            }
                        } else {
                            console.error("userHostingPlan is undefined or empty");
                        }

                    }
                } catch (error) {
                    console.error("Error validating token or fetching data:", error);
                    setIsValid(false);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
    
        validateToken();
    }, [token]);

    if (loading) {
        return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
    }

    if (!isValid) {
        localStorage.removeItem('token');
        return <Login setToken={setToken} />;
    }

    const deleteWebsite = async (websiteId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this website?");
        if (confirmDelete) {
            try {
                const response = await axios.delete(`https://daedalussolutions.online/delete-website/${websiteId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setDomains(domains.filter(website => website.id !== websiteId));
                }
            } catch (error) {
                console.error("Error deleting website:", error);
                alert("There was an error deleting the website. Please try again.");
            }
        }
    };
    
    return (
        <div className="Dashboard">
            <div className='dashboard-websites'>
                <h2>Websites</h2>
                {userHostingPlan && userHostingPlan.length > 0 ? (
                    <Link to="/add-website">
                        <button className='add-website-button'>Add Website</button>
                    </Link>
                    ) : (
                    <Link to="/pricing">
                        <button className='add-website-button'>Add Website</button>
                    </Link>
                )}
                
                {Array.isArray(domains) && domains.length > 0 ? (
                    <ul>
                        {(() => {
                            const items = [];
                            for (let i = 0; i < domains.length; i++) {
                                const website = domains[i];
                                items.push(
                                    <li key={website.id}>
                                        <span className="website-container">
                                            <a href={`https://${website.domain_name}/wp-admin/`} target="_blank" rel="noopener noreferrer">
                                                {website.domain_name}
                                            </a>
                                            <a className="delete-link" href="#" onClick={() => deleteWebsite(website.id)}>[X]</a>
                                        </span>
                                    </li>
                                );
                            }
                            return items;
                        })()}
                    </ul>
                ) : (
                    <p></p>
                )}
            </div>
            <div className='user-info'>
                <div className='user-plan-info'>
                    {userHostingPlan && userHostingPlan.length > 0 ? (
                        <>
                            <div className='plan-info-text'>
                                <h3>Current plan: </h3>
                                <h3>{plan.plan_type}</h3>
                            </div>
                            <div className='plan-info-text'>
                                <h3>Paid until: </h3>
                                <h3>{moment(userHostingPlan[0].end_date).format('MMMM Do YYYY')}</h3>
                            </div>
                            <div className='plan-info-text'>
                                <h3>Next payment due date: </h3>
                                <h3>{moment(userHostingPlan[0].end_date).add(1, 'day').format('MMMM Do YYYY')}</h3>
                            </div>
                            <div className='plan-info-text'>
                                <h3>Websites active: </h3>
                                <h3>{userHostingPlan[0].websites_enabled}</h3>
                            </div>
                            <div className='plan-info-text'>
                                <h3>Websites available: </h3>
                                <h3>{userHostingPlan[0].websites_available}</h3>
                            </div>
                        </>
                    ) : (
                        <div className='plan-info-text'>
                            <h3>No active plan</h3>
                        </div>
                    )}
                </div>
                <div className='user-account-info'>
                    {userHostingPlan && userHostingPlan.length > 0 && userHostingPlan[0].auto_renewal == null && (
                        <Link to={`/checkout/${plan.id}`}>
                            <button>Extend Plan</button>
                        </Link>
                    )}
                    <Link to="/settings"><button>Settings</button></Link>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;