import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import loadingIcon from '../assets/images/transistor_loading_icon.gif'


function AddWebsite() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [addingWebsite, setAddingWebsite] = useState(false);
    const [user, setUserData] = useState(null);
    const [plan, setPlan] = useState(null);
    const [userHostingPlan, setUserHostingPlanData] = useState(null);
    const [domain, setDomain] = useState('');
    const [website_name, setWebsiteName] = useState('');
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    
    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    const response = await axios.get('https://daedalussolutions.online/validate-token', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setIsValid(true);
                        const userResponse = await axios.get('https://daedalussolutions.online/user-data', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserData(userResponse.data);
    
                        const userPlanResponse = await axios.get('https://daedalussolutions.online/user-plan-data', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserHostingPlanData(userPlanResponse.data);
                        
                        if (userPlanResponse.data && userPlanResponse.data.length > 0) {
                            const planResponse = await axios.get(`https://daedalussolutions.online/api/hosting_plans/${userPlanResponse.data[0].hosting_plan_id}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            setPlan(planResponse.data);
                        } else {
                            console.error("userHostingPlan is undefined or empty");
                        }
                    }
                } catch (error) {
                    console.error("Error validating token or fetching data:", error);
                    setIsValid(false);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
    
        validateToken();
    }, [token]);

    if (loading) {
        return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
    }

    if (addingWebsite) {
        return <div className='loading'><p>Your website is being installed. You will receive an email with login credentials momentarily. (Check your spam folder!)<br/><br/> Please wait...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>
    }

    if (!isValid) {
        localStorage.removeItem('token');
        return <Login setToken={setToken} />;
    }

/*     const verifyDomain = async (domain) => {
        try {
            const response = await axios.get(`https://cors-anywhere.herokuapp.com/http://${domain}`);
            return `Domain ${domain} is reachable.`;
        } catch (error) {
            return `Domain ${domain} is not reachable. Have you purchased the domain? If you have, please wait a few minutes and try again. Error: ${error.message}`;
        }
    };     */

    const verifyDNSRecords = async (domain) => {
        try {
            const [aResponse, aaaaResponse, txtResponse] = await Promise.all([
                axios.get(`https://dns.google/resolve?name=${domain}&type=A`),
                axios.get(`https://dns.google/resolve?name=${domain}&type=AAAA`),
                axios.get(`https://dns.google/resolve?name=${domain}&type=TXT`)
            ]);

            const records = [];
    
            if (aResponse.data.Answer) {
                records.push(...aResponse.data.Answer.map(record => ({
                    type: 'A',
                    name: record.name,
                    data: record.data
                })));
            }
    
            if (aaaaResponse.data.Answer) {
                records.push(...aaaaResponse.data.Answer.map(record => ({
                    type: 'AAAA',
                    name: record.name,
                    data: record.data
                })));
            }
    
            if (txtResponse.data.Answer) {
                records.push(...txtResponse.data.Answer.map(record => ({
                    type: 'TXT',
                    name: record.name,
                    data: record.data
                })));
            }
    
            return records;
        } catch (error) {
            console.error("Error fetching DNS records:", error);
            return null; 
        }
    };
    
    

    const handleDomainInputChange = (e) => {
        setDomain(e.target.value);

    };

    const handleNameInputChange = (e) => {
        setWebsiteName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddingWebsite(true);
        setMessage('');
    
        const domainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})+$/;
        if (!domainRegex.test(domain)) {
            setMessage('Please enter a valid domain name.');
            setAddingWebsite(false);
            return;
        }
    

        if (true /* verificationMessage.includes('is reachable')*/) {
            const dnsRecords = await verifyDNSRecords(domain);
            console.log("DNS Records:", dnsRecords);
            if (!dnsRecords) {
                setMessage('Could not verify DNS records. Please try again later.');
                setAddingWebsite(false);
                return;
            }
    
            const requiredRecords = [
                { type: 'A', name: domain, value: '108.61.148.162' },
                { type: 'AAAA', name: domain, value: '2001:19f0:5:5aca:5400:5ff:fe23:e7b8' },
                { type: 'TXT', name: domain, value: 'daedalus-solutions-hosting=v' },
            ];

            console.log(requiredRecords);
    
            const missingRecords = requiredRecords.filter(record => {
                const found = dnsRecords.find(r => {
                    const normalizedRecordName = r.name.endsWith('.') ? r.name.slice(0, -1) : r.name;
                    return normalizedRecordName === record.name && r.data === record.value;
                });
                return !found;
            });
    
            if (missingRecords.length > 0) {
                const missingMessages = missingRecords.map(record => `Missing ${record.type} record: ${record.name} with value ${record.value}`).join(', ');
                setMessage(`DNS verification failed: ${missingMessages} Please check your DNS records then wait a few moments and try again.`);
                setAddingWebsite(false);
                return;
            }
    
            try {
                const response = await fetch('https://daedalussolutions.online/add-website', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({ domain, website_name }),
                });
    
                const data = await response.json();
    
                if (response.ok) {
                    setMessage(`Domain ${domain} added successfully!`);
                } else {
                    setMessage(`Error: ${data.message}`);
                }
            } catch (error) {
                setMessage('');
            }
        }
        setAddingWebsite(false);
    };

  return (
    <div className="addwebsite-form-bg">
      <div className='addwebsite-form'>
        <div className='instructions'>
            <h3>1. Get a domain.</h3>
            <h3>2. Add the following DNS records to your domain.</h3>
            <table>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Value</th>
                        <th>TTL</th>
                    </tr>
                </thead>
                <tbody>
                    <td>A Record</td>
                    <td>@</td>
                    <td>108.61.148.162</td>
                    <td>3 min</td>
                </tbody>
                <tbody>
                    <td>AAAA Record</td>
                    <td>@</td>
                    <td>2001:19f0:0005:5aca:5400:05ff:fe23:e7b8</td>
                    <td>3 min</td>
                </tbody>
                <tbody>
                    <td>TXT Record</td>
                    <td>@</td>
                    <td>daedalus-solutions-hosting=v</td>
                    <td>3 min</td>
                </tbody>
            </table>
            <p><a href="/how-to-add-dns-records">Need help adding DNS records?</a></p>
            <h3>3. Enter your domain name (including extension).</h3>
            <p>Ex. yourdomain.net, yourdomain.business</p>
        </div>
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={domain}
                onChange={handleDomainInputChange}
                placeholder="Enter your domain"
                required
            /><br/>
            <h3>4. Enter the name of your website.</h3>
            <input
                type="text"
                value={website_name}
                onChange={handleNameInputChange}
                placeholder="Enter your website name"
                required
            /><br/>
            <button type="submit" disabled={loading}>
                {loading ? 'Verifying...' : 'Add Domain'}
            </button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default AddWebsite;