import * as React from "react";
import { Link } from 'react-router-dom';

const PricingCard = ({ title, price, annualprice, features, buttonText, id }) => {
  const isContactButton = buttonText === 'Contact';
  return (
    <div className="pricing-card">
      <h2>{title}</h2>
      <p className="price">{price}</p>
      <p className="annual-price">{annualprice}</p>
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      {isContactButton ? (
        <Link to="/contact">
          <button className="btn">{buttonText}</button>
        </Link>
      ) : (
        <Link to={`/checkout/${id}`}>
          <button className="btn">{buttonText}</button>
        </Link>
      )}
    </div>
  );
};

export default PricingCard;