import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import loadingIcon from '../assets/images/transistor_loading_icon.gif'


function Settings() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showConfirmDeletePlan, setShowConfirmDeletePlan] = useState(false);
    const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
    const [user, setUserData] = useState(null);
    const [plan, setPlan] = useState(null);
    const [userHostingPlan, setUserHostingPlanData] = useState(null);
    const [hostingPlanId, setHostingPlanId] = useState(null);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    const response = await axios.get('https://daedalussolutions.online/validate-token', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setIsValid(true);
                        const userResponse = await axios.get('https://daedalussolutions.online/user-data', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        console.log(userResponse.data)
                        setUserData(userResponse.data);
                        setUserId(userResponse.data.id);

                        const userPlanResponse = await axios.get('https://daedalussolutions.online/user-plan-data', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserHostingPlanData(userPlanResponse.data);
                        
                        if (userPlanResponse.data && userPlanResponse.data.length > 0) {
                            const planResponse = await axios.get(`https://daedalussolutions.online/api/hosting_plans/${userPlanResponse.data[0].hosting_plan_id}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            setPlan(planResponse.data);
                            setHostingPlanId(userPlanResponse.data[0].id);
                        } else {
                            console.error("userHostingPlan is undefined or empty");
                        }
                    }
                } catch (error) {
                    console.error("Error validating token or fetching data:", error);
                    setIsValid(false);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
    
        validateToken();
    }, [token]);

    const handleDeletePlan = async () => {
        if (hostingPlanId) {
            try {
                const response = await axios.delete(`https://daedalussolutions.online/api/user_hosting_plans/${hostingPlanId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    alert('Hosting plan deleted successfully');
                    setUserHostingPlanData(null);
                    setPlan(null);
                }
            } catch (error) {
                console.error('Error deleting hosting plan:', error);
                alert('Failed to delete hosting plan');
            } finally {
                setShowConfirmDeletePlan(false);
            }
        }
    };

    const handleDeleteUser = async () => {
        if (userId) {
            try {
                const response = await axios.delete(`https://daedalussolutions.online/api/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    alert('User deleted successfully');
                    setToken(null);
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            } catch (error) {
                console.error('Error deleting user:', error);
                alert('Failed to delete user');
            } finally {
                setShowConfirmDeleteUser(false);
            }
        } else {
            console.log('User id is null.', userId);
        }
    };

    const confirmDeletePlan = () => {
        setShowConfirmDeletePlan(true);
    };

    const confirmDeleteUser = () => {
        setShowConfirmDeleteUser(true);
    };

    if (loading) {
        return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
    }

    if (!isValid) {
        return <Login setToken={setToken} />;
    }

        return (
        <div className="Settings">
            <div className='settings-buttons'> 
                <Link to="/update-email"><button>Update Email</button></Link><br/>
                <Link to="/update-password"><button>Update Password</button></Link><br/>
                <button onClick={confirmDeletePlan}>Cancel Plan</button><br/> 
                <button onClick={confirmDeleteUser}>Delete Account</button><br/> 
            </div>

            {showConfirmDeletePlan && (
                <div className="confirmation-dialog">
                    <p>Are you sure you want to delete your hosting plan?</p>
                    <button onClick={handleDeletePlan}>Yes, Delete</button>
                    <button onClick={() => setShowConfirmDeletePlan(false)}>Cancel</button>
                </div>
            )}

            {showConfirmDeleteUser && (
                <div className="confirmation-dialog">
                    <p>Are you sure you want to delete your account?</p>
                    <button onClick={handleDeleteUser}>Yes, Delete</button>
                    <button onClick={() => setShowConfirmDeleteUser(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
}

export default Settings;
