import * as React from "react";
import { SocialIcon } from 'react-social-icons'
 
function About() {
    return (
        <div className="About">
            <h3>
                Daedalus Solutions offers primarily Wordpress hosting and web development services.
            </h3>

            <div className="questions">
                <div className="question-block">
                    <h4>How does your hosting service compare to other providers?</h4>
                    <p>Our services are cheaper.</p>

                    <table>
                        <thead>
                        <tr>
                            <th>Provider</th>
                            <th>Price (CAD)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Daedalus Solutions</td>
                            <td>$4.00 month, always</td>
                        </tr>
                        <tr>
                            <td>Bluehost</td>
                            <td>$12.99/month</td>
                        </tr>
                        <tr>
                            <td>GoDaddy</td>
                            <td>$6.49/month</td>
                        </tr>
                        <tr>
                            <td>HostGator</td>
                            <td>$4.50/month, renews at $16.49/month</td>
                        </tr>
                        <tr>
                            <td>DreamHost</td>
                            <td>$16.95/month</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="question-block">
                    <h4>Do you offer cPanel?</h4>
                    <p>To optimize performance and keep costs low we do not use cPanel. In most cases the Wordpress admin panel is sufficient in managing your site.
                        Do not hesitate to contact us if you require additional resources.
                    </p>
                </div>
                <div className="question-block">
                    <h4>Where are the servers located?</h4>
                    <p>The servers are located in the United States.</p>
                </div>
                <div className="question-block">
                    <h4>How long will it take to get my custom site up and running?</h4>
                    <p>
                        If you purchased the basic, standard or managed plan then your Wordpress admin panel will be available almost immediatley after adding your website on the dashboard.
                        <br/>
                        <br/>
                        If you purchased a custom plan, more information will be required to fulfill your needs. We will reach out to get this information. Your site will be live within 5 business days. 
                    </p>
                </div>
            </div>

            <div className="questions">
                <div className="question-block">
                    <h4>How can I get a refund?</h4>
                    <p>
                        If you are within the first 30 days of your plan then contact support@daedalussolutions.online and you will receive a refund as soon as possible.
                        <br/>
                        <br/>
                        If you are after the first 30 days of your plan and unsatisfied with our services, please contact support@daedalussolutions.online and we will do our 
                        best to resolve all issues and concerns.
                    </p>
                </div>
                <div className="question-block">
                    <h4>Do you offer email services?</h4>
                    <p>As of this time we do not offer any email services. If you are looking to conduct email marketing or otherwise sending bulk emails then you would need a third-party
                        email service even if we did offer an email server. Many hosting services strictly limit the volume of emails available to prevent abuse through spamming.
                         Please contact support@daedalussolutions.online for a reccomendation on free or affordable email services for both transactional and marketing purposes.
                    </p>
                </div>
                <div className="question-block">
                    <h4>What does your managed Wordpress hosting plan offer?</h4>
                    <p>
                        The managed plan is for those who want to create their website and not manage it regularly. Websites require maintenance to ensure usability, reliability and
                        to keep up with security updates. If you have the Managed plan, you can build your website and forget it while it still offers all the rewards.
                    </p>
                </div>
                <div className="question-block">
                    <h4>What payment options do you accept, and are there any hidden fees?</h4>
                    <p>
                        We accept all major credit cards and Paypal. No hidden fees.
                    </p>
                </div>
            </div>
            

            <div className='social-media-icons'>
                <SocialIcon className='linkedin' bgColor="currentColor" fgColor="white" target="_blank" url="https://www.linkedin.com/in/dylan-cassell-" />
                <SocialIcon className='github' bgColor="currentColor" fgColor="white" target="_blank" url="https://github.com/daedalussolutions" />
                <SocialIcon className='twitter' bgColor="currentColor" fgColor="white" target="_blank" url="https://twitter.com/daedalussltns" />
            </div>

        </div>
    );
}

export default About;