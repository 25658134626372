import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import paymentLogos from "../assets/images/payment-logos.png";
import loadingIcon from '../assets/images/transistor_loading_icon.gif'
import Login from './Login';

const appearance = {
  style: {
    base: {
      color: '#fff',
      fontFamily: '"Roboto Mono", monospace',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '10px',
      '::placeholder': {
        color: '#fff',
      },
    },
    invalid: {
      color: '#fff',
      iconColor: '#fff',
    },
    complete: {
      color: '#fff',
    },
  },
};

function Checkout() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const { id: selectedPlanId } = useParams();
  const [userData, setUserData] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [months, setMonths] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [autoRenew, setAutoRenew] = useState(true);
  const [payPalReady, setPayPalReady] = useState(false);
  const cardElementRef = useRef(null);


  useEffect(() => {
    const validateToken = async () => {
      if (token) {
        try {
          const response = await axios.get('https://daedalussolutions.online/validate-token', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response.status === 200) {
            setIsValid(true);
            const userResponse = await axios.get('https://daedalussolutions.online/user-data', {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            });
          setUserData(userResponse.data);
            const planResponse = await axios.get(`https://daedalussolutions.online/api/hosting_plans/${selectedPlanId}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            setPlan(planResponse.data);
            setTotalPrice(planResponse.data.price);

          }
        } catch (error) {
          console.error("Error validating token or fetching data:", error);
          setIsValid(false);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    validateToken();
  }, [token, selectedPlanId]);

  useEffect(() => {
    if (plan) {
      if (months === 12) {
        setTotalPrice(plan.annual_price); 
      } else {
        setTotalPrice(plan.price);
      }
    }
  }, [months, plan]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
  
    if (paymentMethodError) {
      setError(paymentMethodError.message);
      return;
    }

  
    try { 
      const email = userData.email;
      if (autoRenew) {
        const paymentMethodId = paymentMethod.id;
  
        const subscriptionResponse = await axios.post('https://daedalussolutions.online/create-subscription', {
          email,
          paymentMethodId,
          selectedPlanId,
          months,
        });
  
        if (subscriptionResponse.data.error) {
          console.log(subscriptionResponse.data.error);
          setError('There was an issue with payment.');
        } else {
          const subscriptionId = subscriptionResponse.data.subscriptionId; 
          console.log('Subscription created:', subscriptionId);
          const userHostingPlanResponse = await axios.post('https://daedalussolutions.online/api/user_hosting_plans', {
            hosting_plan_id: selectedPlanId,
            months_purchased: months,
            total_price: totalPrice,
            auto_renewal: autoRenew,
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          
          const receiptResponse = await axios.post('https://daedalussolutions.online/create-receipt', {
            user_id: userData.id,
            hosting_plan_id: selectedPlanId,
            payment_method: paymentMethod.type,
            amount: totalPrice,
            months: months
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          console.log(receiptResponse);
          const receiptId = receiptResponse.data.receiptId;
          navigate(`/receipt/${receiptId}`);
        }
      } else {
        const response = await axios.post('https://daedalussolutions.online/create-payment-intent', {
          amount: totalPrice * 100,
          payment_method: paymentMethod.type,
          email,
        });
  
        const { clientSecret } = response.data;
  
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });
  
        if (result.error) {
          setError(result.error.message);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            const userHostingPlanResponse = await axios.post('https://daedalussolutions.online/api/user_hosting_plans', {
              hosting_plan_id: selectedPlanId,
              months_purchased: months,
              total_price: totalPrice,
              autoRenew: autoRenew,
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

            const receiptResponse = await axios.post('https://daedalussolutions.online/create-receipt', {
              user_id: userData.id,
              hosting_plan_id: selectedPlanId,
              payment_method: paymentMethod.type,
              amount: totalPrice,
              months: months
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            const receiptId = receiptResponse.data.receiptId; 
            navigate(`/receipt/${receiptId}`);
          }
        }
      }
    } catch (err) {
      setError(err.message); 
    }
  };

  useEffect(() => {
    if (isValid){
      const timer = setTimeout(() => {
      const script = document.createElement('script');
      script.src = "https://www.paypal.com/sdk/js?client-id=AZ5TWZu06R_PN3hNG04H0QGnPXmQ-QYUX27hf21uLwP_HUv59C7zRXi_RlWkLwrYY647XBRLTHP-nRWR&currency=CAD&components=buttons&disable-funding=card";
      script.async = true;
      script.onload = () => {
        setPayPalReady(true); 
      };
      script.onerror = () => {
        console.error("PayPal SDK failed to load.");
      };
      document.body.appendChild(script);
    }, 1000); 

    return () => clearTimeout(timer);
   }
  }, [isValid]); 

  useEffect(() => {
    if (payPalReady) {
      window.paypal.Buttons({
        style: {
          color: 'black',
        },
        createOrder: async (data, actions) => {
          const response = await axios.post('https://daedalussolutions.online/create-paypal-order', {
            amount: totalPrice,
          });
          return response.data.id;
        },
        onApprove: async (data, actions) => {
          const response = await axios.post('https://daedalussolutions.online/capture-paypal-order', {
            orderId: data.orderID,
          });
          if (response.data.status === 'COMPLETED') {
            const userHostingPlanResponse = await axios.post('https://daedalussolutions.online/api/user_hosting_plans', {
              hosting_plan_id: selectedPlanId,
              months_purchased: months,
              total_price: totalPrice,
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            const receiptResponse = await axios.post('https://daedalussolutions.online/create-receipt', {
              user_id: userData.id,
              hosting_plan_id: selectedPlanId,
              payment_method: 'PayPal',
              amount: totalPrice,
              months: months
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            const receiptId = receiptResponse.data.receiptId; 
            navigate(`/receipt/${receiptId}`); 
          } else {
            console.error('Payment not completed.');
          }
        },
        onError: (err) => {
          console.error('An error occurred during the transaction.', err);
        },
      }).render('#paypal-button-container');
    }
    setPayPalReady(false);
  }, [payPalReady, totalPrice, selectedPlanId, months, token, navigate]);

  if (loading) {
    return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
  }

  if (!isValid) {
    localStorage.removeItem('token');
    return <Login setToken={setToken} />;
  }

  if (!plan) {
    return <p>No plan found.</p>;
  }

  return (
    <div className="Checkout">
      <div className='Cart'>
        <h2>Cart</h2>
        <p>{plan.plan_type} Wordpress Hosting .............. {totalPrice} CAD</p>
        <div>
          <label>
            Select Duration:
          </label>
          <select 
            value={months} 
            onChange={(e) => setMonths(Number(e.target.value))} 
          >
            <option value={1}>1 Month</option>
            <option value={12}>12 Months</option>
          </select>
        </div>
        <p className='total'>Total: {totalPrice} CAD</p>
      </div>
      <div className='checkout-form-bg'>
        <div className='checkout-form'>
          <form onSubmit={handleSubmit}>
            <div>
              <label>
                Card Details:
              </label>
              <div className="card-element-container">
                <CardElement options={appearance} />
              </div>
            </div>
            <img src={paymentLogos} alt='Payment Logos' width={250}></img>
            <div>
          <label>
            <input 
              className='checkout-checkbox'
              type="checkbox" 
              checked={autoRenew} 
              onChange={(e) => setAutoRenew(e.target.checked)} 
            />
            Enable Auto-Renew
          </label>
        </div>
            <button type="submit" disabled={!stripe}>Pay Now</button>
            {/* Error message section */}
            {error && (
            <div className="error-message">
              <p>{error}</p>
            </div>
            )}
            <div className='payment-seperation'></div>
            <div id="paypal-button-container" style={{marginLeft: '25%', marginRight: '25%', marginTop: '5%'}}></div>
            <p id="result-message"></p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
