import * as React from "react";
 
function TermsOfService() {
    return (
    <div className="TermsOfService">
        <h1>Terms of Service</h1>
        <p>Last updated: November 2, 2024</p>

        <h2>Acceptance of Terms</h2>
        <p>
            By accessing or utilizing the services provided by Daedalus Solutions ("us", "we", or "our"), you ("User", "you", or "your") agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you must refrain from using our services.
        </p>

        <h2>Modification of Terms</h2>
        <p>
            We reserve the right to amend or modify these Terms at any time, at our sole discretion. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of our services following the posting of any changes constitutes your acceptance of such changes.
        </p>

        <h2>Services Provided</h2>
        <p>
            Daedalus Solutions provides web hosting services and related functionalities as described on our website. We endeavor to maintain the availability and performance of our services; however, we do not guarantee uninterrupted or error-free service.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
            To the fullest extent permitted by applicable law, Daedalus Solutions shall not be liable for any indirect, incidental, consequential, special, or punitive damages, including but not limited to loss of profits, data, or use, arising out of or in connection with your use of our services, even if we have been advised of the possibility of such damages. Your sole remedy for dissatisfaction with our services is to discontinue use of the services.
        </p>

        <h2>Indemnification</h2>
        <p>
            You agree to indemnify, defend, and hold harmless Daedalus Solutions, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees) arising out of or in any way connected with your access to or use of our services, or your violation of these Terms.
        </p>

        <h2>User Misuse and Abuse</h2>
        <p>
            Users are prohibited from engaging in any form of misuse or abuse of our services. This includes, but is not limited to, the distribution of illegal, harmful, or offensive material. If we determine, at our sole discretion, that a User has engaged in such activities, we reserve the right to suspend or terminate their account immediately, without prior notice. Any material deemed illegal will be removed, and the User's access to our services will be permanently revoked.
        </p>

        <h2>Severability</h2>
        <p>
            If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, such provision shall be limited or eliminated to the minimum extent necessary so that the remaining provisions of these Terms shall remain in full force and effect.
        </p>

        <h2>Contact Us</h2>
        <p>
            If you have any questions about these Terms, please contact us:
        </p>
        <ul>
            <li>Email: dylan@daedalussolutions.online</li>
        </ul>
    </div>
    );
}

export default TermsOfService;