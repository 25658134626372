import * as React from "react";
import PricingCard from './PricingCard';

const Pricing = () => {
  const pricingTiers = [
    {
      title: 'Basic',
      price: '$4/month',
      annualprice: 'Or $43 Annually',
      features: ['1 WordPress Website', '30 Day Money-Back Guarantee', 'Free SSL', 'Firewall', 'DDoS Protection', 'Online Support'],
      buttonText: 'Order Now',
      id: 2
    },
    {
      title: 'Standard',
      price: '$9/month',
      annualprice: 'Or $99 Annually',
      features: ['5 WordPress Websites', '30 Day Money-Back Guarantee', 'Free SSL', 'Firewall', 'DDoS Protection', 'Online Support'],
      buttonText: 'Order Now',
      id: 1
    },
    {
      title: 'Managed',
      price: '$59/month',
      annualprice: 'Or $639 Annually',
      features: ['10 WordPress Websites', 'Automatic Updates', 'Plugin Management', 'Daily Backups' , '30 Day Money-Back Guarantee', 'Free SSL', 'Firewall', 'DDoS Protection', 'Online Support'],
      buttonText: 'Order Now',
      id: 3
    },
    {
      title: 'Custom',
      price: '$149/month',
      annualprice: 'Or $1499 Annually',
      features: ['10 WordPress Websites', '1 Custom Developed Website', 'Website Content Management','Graphic Design Services', 'SEO', 'Enhanced Cybersecurity Measures', 'Automatic Updates', 'Plugin Management', 'Daily Backups' , '30 Day Money-Back Guarantee', 'Free SSL', 'Firewall', 'DDoS Protection', 'Online Support'],
      buttonText: 'Order Now',
      id: 4
    },
  ];

  return (
    <div className="Pricing">
        <div className="pricing-page">
          <h1>Hosting Plans</h1>
          <p></p>
          <div className="pricing-cards">
            {pricingTiers.map((tier, index) => (
              <PricingCard key={index} {...tier} />
            ))}
          </div>
          <p>No plan to meet your requirements? <a href="/contact">Contact us.</a></p>
        </div>
    </div>
  );
};

export default Pricing;