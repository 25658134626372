import * as React from "react";
import logo from "../assets/images/site-logo2.png"
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';



 
function Header() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
            <header className="header">
                <Link to="/"><img src={logo} alt="Company Logo" className="logo"/></Link>
                <nav className="nav">
                    <ul className="nav-left">
                        <li><Link to ="/pricing">Pricing</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                    <ul className="nav-right">
                        {token ? (
                            <>
                                <li><Link to="/dashboard">Dashboard</Link></li>
                                <li><a onClick={handleLogout} className="logout-button">Logout</a></li>
                            </>
                        ) : (
                            <>
                                <li><Link to="/register">Register</Link></li>
                                <li><Link to="/login">Login</Link></li>
                            </>
                        )}
                    </ul>
                </nav>
            </header>
    );
}

export default Header;